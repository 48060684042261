import axios from "axios";
import {SubmissionError} from "redux-form";
import Cookies from "universal-cookie";
import url from "url";

import {
	POPULAR_BOOKS,
	RECOMMENDED_BOOKS,
	AUTOFILL_BOOKS,
	SEARCH_BOOKS,
	GET_BOOK,
	SIMILAR_BOOKS,
	CONTACT,
	SIGN_UP,
	LOG_IN,
	TOKEN_LOG_IN,
	CREATE_PASSWORD_RESET,
	UPDATE_PASSWORD_RESET,
	LOG_OUT,
	GET_SHELVES,
	GET_READING_SPEED,
	GET_READING_SPEED_TEST,
	GET_READING_SPEED_TESTS,
	CLEAR_READING_SPEED_TEST,
	UPDATE_PASSWORD,
	GET_ACCOUNT,
	UPDATE_ACCOUNT,
	ADD_BOOK_TO_SHELF,
	DELETE_BOOK_FROM_SHELF,
	CREATE_SHELF,
	GET_GENRES,
	CREATE_SUGGESTION,
	CLEAR_SUGGESTION,
	CREATE_REPORT,
	ALL_POPULAR_BOOKS,
	ALL_RECOMMENDED_BOOKS,
	IS_SUCCESS,
	IS_ERROR,
} from "./types";
import {
	bookUrl,
	FOUR_YEARS_IN_SECONDS,
	linkGoodreadsAccountUrl,
} from "../config";
import history from "../history";
import {cloneDeep} from "lodash";

const cookies = new Cookies();

// books
export const popularAndRecommendedBooks = () => async dispatch => {
	const response = await axios.get("/books/popular-and-recommended");
	dispatch({type: POPULAR_BOOKS, payload: response.data.popularBooks});
	dispatch({type: RECOMMENDED_BOOKS, payload: response.data.recommendedBooks});
};

export const getAllPopularBooks = () => async dispatch => {
	const response = await axios.get("/books/popular/all");
	dispatch({type: ALL_POPULAR_BOOKS, payload: response.data});
};

export const addToPopularBooks = formValues => async (dispatch, getState) => {
	if (!getState().account.loggedIn) {
		return;
	}
	const options = {
		headers: {Authorization: `Bearer ${getState().account.token}`},
	};
	await axios.post("/books/popular/add", formValues, options);
};

export const deletePopularBookFromList = bookId => async (
	dispatch,
	getState,
) => {
	if (!getState().account.loggedIn) {
		return;
	}
	const options = {
		headers: {Authorization: `Bearer ${getState().account.token}`},
	};
	await axios.delete(`/books/popular/${bookId}`, options);
};

export const recommendedBooks = () => async dispatch => {
	const response = await axios.get("/books/recommended");
	dispatch({type: RECOMMENDED_BOOKS, payload: response.data});
};

export const getAllRecommendedBooks = () => async dispatch => {
	const response = await axios.get("/books/recommended/all");
	dispatch({type: ALL_RECOMMENDED_BOOKS, payload: response.data});
};

export const addToRecommendedBooks = formValues => async (
	dispatch,
	getState,
) => {
	if (!getState().account.loggedIn) {
		return;
	}
	const options = {
		headers: {Authorization: `Bearer ${getState().account.token}`},
	};
	await axios.post("/books/recommended/add", formValues, options);
};

export const deleteRecommendedBookFromList = bookId => async (
	dispatch,
	getState,
) => {
	if (!getState().account.loggedIn) {
		return;
	}
	const options = {
		headers: {Authorization: `Bearer ${getState().account.token}`},
	};
	await axios.delete(`/books/recommended/${bookId}`, options);
};

export const autofillBooks = qs => async dispatch => {
	if (qs === "") {
		dispatch({type: AUTOFILL_BOOKS, payload: null});
	} else {
		const response = await axios.get(`/books/autofill/${qs}`);
		dispatch({type: AUTOFILL_BOOKS, payload: response.data});
	}
};

export const searchBooks = qs => async dispatch => {
	try {
		const response = await axios.get(`/books/search/${qs}`);
		dispatch({type: SEARCH_BOOKS, payload: response.data});
		history.push(`/results/${qs}`);
	} catch (e) {
		throw new SubmissionError(e.response.data);
	}
};

export const searchBooksTop = qs => async dispatch => {
	const response = await axios.get(`/books/search/${qs}/top`);
	const {id, title} = response.data;
	history.push(bookUrl(id, title));
	getBook(id);
};

export const getBook = (bookId = null, isbn = null) => async dispatch => {
	if (bookId === null && isbn === null) {
		throw new Error("Error, missing ID and ISBN");
	}
	let url = "/books";
	if (bookId !== null) {
		url += `/id/${bookId}`;
	} else if (isbn !== null) {
		url += `/isbn/${isbn}`;
	}
	try {
		const response = await axios.get(url);
		dispatch({
			type: GET_BOOK,
			status: IS_SUCCESS,
			payload: {
				status: IS_SUCCESS,
				data: response.data,
			},
		});
	} catch (error) {
		dispatch({
			type: GET_BOOK,
			payload: {
				status: IS_ERROR,
				error: {
					bookId: bookId,
					statusCode: error.response.status,
					message: error.response.data,
				},
			},
		});
	}
};

export const similarBooks = isbn => async dispatch => {
	const response = await axios.get(`/books/similar-to/${isbn}`);
	dispatch({type: SIMILAR_BOOKS, payload: response.data});
};

// contact
export const contact = formValues => async dispatch => {
	try {
		const response = await axios.post("/contact", formValues);
		dispatch({type: CONTACT, payload: response.data});
	} catch (e) {
		throw new SubmissionError(e.response.data);
	}
};

// account
export const signUp = formValues => async dispatch => {
	try {
		const response = await axios.post("/auth/sign-up", formValues);
		dispatch({type: SIGN_UP, payload: response.data});
	} catch (e) {
		throw new SubmissionError(e.response.data);
	}
};

export const logIn = formValues => async dispatch => {
	try {
		const response = await axios.post("/auth/log-in", formValues);
		const token = response.data.token;
		const role = response.data.role;
		if (!token) {
			throw new Error("Expected token in HTTP response");
		}
		if (role) {
			localStorage.setItem("role", role);
		}
		dispatch({type: LOG_IN, payload: response.data});
		history.push("/account/books");
	} catch (e) {
		throw new SubmissionError(e.response.data);
	}
};

export const emailLogIn = code => async dispatch => {
	try {
		const response = await axios.patch("/auth/email", {code});
		const token = response.data.token;
		if (!token) {
			throw new Error("Expected token in HTTP response");
		}
		dispatch({type: TOKEN_LOG_IN, payload: token});
		window.alert("Email verified!");
	} catch (e) {
		window.alert("Couldn't verify your email. Your email may have already been verified or this link may be out of date.");
	}
	history.push("/account/books");
};

export const tokenLogIn = token => dispatch => {
	dispatch({type: TOKEN_LOG_IN, payload: token});
};

export const sendVerificationEmail = () => async (_dispatch, getState) => {
	if (!getState().account.loggedIn) {
		return;
	}
	const options = {
		headers: {Authorization: `Bearer ${getState().account.token}`},
	};
	const body = {};
	await axios.post("/account/resend-confirm-email", body, options);
};

export const createPasswordReset = formValues => async dispatch => {
	try {
		const response = await axios.post("/account/password/reset", formValues);
		dispatch({type: CREATE_PASSWORD_RESET, payload: response.data});
	} catch (e) {
		throw new SubmissionError(e.response.data);
	}
};

export const updatePasswordReset = formValues => async dispatch => {
	try {
		const response = await axios.patch("/account/password/reset", formValues);
		dispatch({type: UPDATE_PASSWORD_RESET, payload: response.data});
	} catch (e) {
		throw new SubmissionError(e.response.data);
	}
};

export const getLinkGoodreadsCode = () => async (_dispatch, getState) => {
	try {
		const options = {
			headers: {Authorization: `Bearer ${getState().account.token}`},
		};
		const response = await axios.get("/auth/goodreads/auth-code", options);
		window.location.href = url.resolve(
			linkGoodreadsAccountUrl,
			response.data.code,
		);
	} catch (e) {
		throw new SubmissionError(e.response.data);
	}
};

export const logOut = () => async dispatch => {
	localStorage.clear();
	dispatch({type: LOG_OUT});
};

export const getShelves = params => async (dispatch, getState) => {
	if (params) {
		const options = {
			headers: {Authorization: `Bearer ${getState().account.token}`},
		};
		try {
			const response = await axios.get(
				`/shelves/${params?.pageNumber}`,
				options,
			);
			dispatch({type: GET_SHELVES, payload: response.data});
		} catch (e) {
			if (e.response.status !== 403) {
				throw e;
			}
		}
	}
};

export const getReadingSpeed = (force = false) => async (
	dispatch,
	getState,
) => {
	if (getState().account.loggedIn) {
		if (getState().account.readingSpeed !== null) {
			return;
		}
		const options = {
			headers: {Authorization: `Bearer ${getState().account.token}`},
		};
		const response = await axios.get("/account/reading-speed", options);
		dispatch({type: GET_READING_SPEED, payload: response.data});
	} else {
		// attempt to get cookie
		const personalizedReadingSpeed = cookies.get("personalizedReadingSpeed", {
			path: "/",
		});
		if (personalizedReadingSpeed) {
			dispatch({
				type: GET_READING_SPEED,
				payload: {personalizedReadingSpeed},
			});
		}
	}
};

export const getReadingSpeedTests = () => async (dispatch, getState) => {
	let options = {};
	if (getState().account.loggedIn) {
		options.headers = {Authorization: `Bearer ${getState().account.token}`};
	}
	const response = await axios.get("/account/reading-speed-test", options);
	dispatch({type: GET_READING_SPEED_TESTS, payload: response.data});
};

export const getReadingSpeedTest = params => async (dispatch, getState) => {
	if (params) {
		const {testId} = params;
		let options = {};
		if (getState().account.loggedIn) {
			options.headers = {Authorization: `Bearer ${getState().account.token}`};
		}
		const response = await axios.get(
			`/account/reading-speed-test/${testId}`,
			options,
		);
		dispatch({type: GET_READING_SPEED_TEST, payload: response.data});
	} else {
		const response = await axios.get("/get-random-test");
		const {text, id} = response?.data;
		dispatch({
			type: GET_READING_SPEED_TEST,
			payload: {
				testId: id,
				text,
				wordCount: response?.data?.wordCount,
			},
		});
	}
};

export const updateReadingSpeedTest = ({ms}) => async (dispatch, getState) => {
	const {wordCount, testId} = getState().readingTest.currentTest;
	const wpm = Math.round((wordCount * 1000 * 60) / ms);
	const {loggedIn, token} = getState().account;
	if (!loggedIn) {
		// save for 4 years
		cookies.set("personalizedReadingSpeed", wpm, {
			path: "/",
			maxAge: 60 * 60 * 24 * 365 * 4,
		});
	}
	try {
		let options = {};
		if (loggedIn) {
			options = {
				headers: {Authorization: `Bearer ${token}`},
			};
		}
		const body = {ms};
		const response = await axios.post(
			`/account/reading-speed-test/${testId}`,
			body,
			loggedIn ? options : {},
		);
		// response contains newly computed reading speed if user logged in
		const payload = loggedIn ? response.data : {personalizedReadingSpeed: wpm};
		dispatch({type: GET_READING_SPEED, payload});
	} catch (e) {
		throw new Error("Failed to update reading speed");
	}
};

export const setReadingSpeedOverride = params => async (dispatch, getState) => {
	const readingSpeedOverride = Number(params.readingSpeedOverride);
	const {loggedIn, token} = getState().account;
	if (!loggedIn) {
		cookies.set("personalizedReadingSpeed", readingSpeedOverride, {
			path: "/",
			maxAge: FOUR_YEARS_IN_SECONDS,
		});
		dispatch({
			type: GET_READING_SPEED,
			payload: {personalizedReadingSpeed: readingSpeedOverride},
		});
		return;
	}

	try {
		const options = {
			headers: {Authorization: `Bearer ${token}`},
		};
		const body = {readingSpeedOverride};
		await axios.post("/account/reading-speed-override", body, options);
		dispatch({
			type: GET_READING_SPEED,
			payload: {personalizedReadingSpeed: readingSpeedOverride},
		});
	} catch (e) {
		throw new Error("Failed to update reading speed");
	}
};

export const clearReadingSpeedOverride = () => async (dispatch, getState) => {
	cookies.remove("personalizedReadingSpeed", {path: "/"});
	const {loggedIn, token} = getState().account;
	if (!loggedIn) {
		dispatch({
			type: GET_READING_SPEED,
			payload: {personalizedReadingSpeed: null},
		});
		return;
	}
	try {
		const options = {
			headers: {Authorization: `Bearer ${token}`},
		};
		await axios.delete("/account/reading-speed-override", options);
		// re-request reading speed
		const response = await axios.get("/account/reading-speed", options);
		dispatch({type: GET_READING_SPEED, payload: response.data});
	} catch (e) {
		throw new Error("Failed to update reading speed");
	}
};

export const clearReadingSpeedTest = formValues => dispatch => {
	dispatch({type: CLEAR_READING_SPEED_TEST});
};

export const updatePassword = formValues => async (dispatch, getState) => {
	try {
		const options = {
			headers: {Authorization: `Bearer ${getState().account.token}`},
		};
		const response = await axios.patch(
			"/account/password",
			formValues,
			options,
		);
		dispatch({type: UPDATE_PASSWORD, payload: response.data});
	} catch (e) {
		throw new SubmissionError(e.response.data);
	}
};

export const getAccount = () => async (dispatch, getState) => {
	if (!getState().account.loggedIn) {
		return;
	}
	try {
		const options = {
			headers: {Authorization: `Bearer ${getState().account.token}`},
		};
		const response = await axios.get("/account", options);
		dispatch({type: GET_ACCOUNT, payload: response.data});
	} catch (e) {
		throw new Error("Failed to get account data");
	}
};

export const updateAccount = formValues => async (dispatch, getState) => {
	try {
		const options = {
			headers: {Authorization: `Bearer ${getState().account.token}`},
		};
		const response = await axios.patch("/account", formValues, options);
		dispatch({type: UPDATE_ACCOUNT, payload: response.data});
	} catch (e) {
		throw new SubmissionError(e.response.data);
	}
};

export const addBookToShelf = ({shelfId, bookId}) => async (
	dispatch,
	getState,
) => {
	const oldShelves = cloneDeep(getState().account.shelves);
	try {
		const options = {
			headers: {Authorization: `Bearer ${getState().account.token}`},
		};
		const shelf = getState().account.shelves.find(s => s.name === shelfId);
		const expectedPayload = {
			...shelf,
			books: [...shelf.books, {id: bookId}],
		};

		// preliminary dispatch
		dispatch({type: ADD_BOOK_TO_SHELF, payload: expectedPayload});
		const response = await axios.post(
			`/shelves/${shelfId}/book`,
			{bookId},
			options,
		);
		dispatch({type: ADD_BOOK_TO_SHELF, payload: response.data});
	} catch (e) {
		// try (probably in vain) to undo
		dispatch({type: GET_SHELVES, payload: oldShelves});
	}
};

export const deleteBookFromShelf = ({shelfId, bookId}) => async (
	dispatch,
	getState,
) => {
	const oldShelves = cloneDeep(getState().account.shelves);
	try {
		const options = {
			headers: {Authorization: `Bearer ${getState().account.token}`},
		};
		const expectedPayload = {response: "success", name: shelfId, bookId};

		// preliminary dispatch
		dispatch({type: DELETE_BOOK_FROM_SHELF, payload: expectedPayload});
		const response = await axios.delete(
			`/shelves/${shelfId}/book/${bookId}`,
			options,
		);
		dispatch({type: DELETE_BOOK_FROM_SHELF, payload: response.data});
	} catch (e) {
		// try (probably in vain) to undo
		dispatch({type: GET_SHELVES, payload: oldShelves});
	}
};

export const createShelf = formValues => async (dispatch, getState) => {
	try {
		const options = {
			headers: {Authorization: `Bearer ${getState().account.token}`},
		};
		const response = await axios.post("/shelves", formValues, options);
		dispatch({type: CREATE_SHELF, payload: response.data});
		return true;
	} catch (e) {
		throw new SubmissionError(e.response.data);
	}
};

// suggest a book
export const getGenres = () => async dispatch => {
	const response = await axios.get("/genres");
	dispatch({type: GET_GENRES, payload: response.data});
};

export const createSuggestion = formValues => async (dispatch, getState) => {
	try {
		const options = {
			headers: {Authorization: `Bearer ${getState().account.token}`},
		};
		const response = await axios.post(
			"/books/suggest",
			formValues,
			getState().account.loggedIn ? options : {},
		);
		dispatch({type: CREATE_SUGGESTION, payload: response.data});
		const min = formValues.readingTimeRange[0];
		const max = formValues.readingTimeRange[1];
		if (min === 0 && max === 0) {
			history.push("/recommended");
			return;
		}
		history.push(`/recommended/${min}-${max}`);
	} catch (e) {
		throw new SubmissionError(e.response.data);
	}
};

export const clearSuggestion = () => async dispatch => {
	dispatch({type: CLEAR_SUGGESTION});
};

export const createReport = formValues => async (dispatch, getState) => {
	try {
		const options = {
			headers: {Authorization: `Bearer ${getState().account.token}`},
		};
		const response = await axios.post(
			"/report",
			formValues,
			getState().account.loggedIn ? options : {},
		);
		dispatch({type: CREATE_REPORT, payload: response.data});
	} catch (e) {
		throw new SubmissionError(e.response.data);
	}
};
