import React, {Component} from "react";
import {Container} from "reactstrap";
import PropTypes from "prop-types";

import {ReactComponent as BritCoLogo} from "../components/svg/logos/BritCo-Logo.svg";
import {ReactComponent as BustleLogo} from "../components/svg/logos/Bustle-Logo.svg";
import {ReactComponent as BuzzFeedLogo} from "../components/svg/logos/BuzzFeed-Logo.svg";
import {ReactComponent as LifeHackerLogo} from "../components/svg/logos/LifeHacker-Logo.svg";
import {ReactComponent as MakeUseOfLogo} from "../components/svg/logos/MakeUseOf-Logo.svg";
import {ReactComponent as NettedLogo} from "../components/svg/logos/Netted-Logo.svg";
import {ReactComponent as NewYorkTimesLogo} from "../components/svg/logos/NewYorkTimes-Logo.svg";
import {ReactComponent as PittsburghPostLogo} from "../components/svg/logos/PittsburghPost-Logo.svg";
import {ReactComponent as SimonSchusterLogo} from "../components/svg/logos/SimonSchuster-Logo.svg";
import {ReactComponent as TheIndependentLogo} from "../components/svg/logos/TheIndependent-Logo.svg";
import {ReactComponent as EconomistLogo} from "../components/svg/logos/Economist-Logo.svg";
import {ReactComponent as YahooLogo} from "../components/svg/logos/Yahoo-Logo.svg";
import {ReactComponent as TimeoutLogo} from "../components/svg/logos/Timeout-Logo.svg";

const STYLE = {height: "80px", padding: "15px"};

class FeaturedLogos extends Component {
	renderLogo(logo, i) {
		const {fillClass, showLinks} = this.props;
		if (showLinks) {
			return (
				<a key={i} href={logo.link} target="_blank" rel="noopener noreferrer">
					<logo.component className={fillClass} style={STYLE} />
				</a>
			);
		}
		return <logo.component key={i} className={fillClass} style={STYLE} />;
	}
	render() {
		const logos = [
			{
				component: EconomistLogo,
				link:
					"https://www.economist.com/interactive/graphic-detail/2024/07/26/how-long-would-it-take-to-read-the-greatest-books-of-all-time",
			},
			{
				component: NewYorkTimesLogo,
				link:
					"https://well.blogs.nytimes.com/2015/11/16/doing-online-homework-to-help-children-do-theirs/",
			},
			{
				component: LifeHackerLogo,
				link:
					"https://lifehacker.com/find-out-how-long-it-will-take-you-to-read-almost-any-b-1723514824",
			},
			{
				component: BuzzFeedLogo,
				link: "https://www.BuzzFeed.com/daves4/useful-fun-websites",
			},
			{
				component: TheIndependentLogo,
				link:
					"https://www.independent.co.uk/arts-entertainment/books/news/find-out-how-long-it-will-take-you-to-read-the-classics-a6673326.html",
			},
			{
				component: YahooLogo,
				link:
					"https://www.yahoo.com/tech/s/8-amazing-websites-didn-t-know-existed-check-180019325.html",
			},
			{
				component: TimeoutLogo,
				link:
					"https://www.timeoutdubai.com/news/437573-ten-quirky-websites-to-check-out-for-something-different",
			},
			{
				component: MakeUseOfLogo,
				link: "https://www.makeuseof.com/tag/5-tools-discovering-time-went/",
			},
			{
				component: NettedLogo,
				link:
					"http://netted.net/extra-netted/calculate-how-long-a-book-will-take-to-read/",
			},
			{
				component: BustleLogo,
				link:
					"https://www.bustle.com/articles/107201-how-long-does-it-take-to-read-moby-dick-this-website-tells-you-how-long-it-takes",
			},
			{
				component: PittsburghPostLogo,
				link:
					"http://www.post-gazette.com/ae/cybertainment/2015/08/28/Cybertainment-Site-offers-reading-times-for-books/stories/201508280120",
			},
			{
				component: SimonSchusterLogo,
				link:
					"https://www.simonandschuster.com/getliterary/olympic-games-for-book-lovers/",
			},
			{
				component: BritCoLogo,
				link: "https://www.brit.co/how-long-to-read-this-website/",
			},
		];
		const maxLogos = this.props.maxLogos || logos.length;

		return (
			<Container>
				<h2>Featured By</h2>
				<div className="d-flex flex-wrap justify-content-around ">
					{logos.slice(0, maxLogos).map(this.renderLogo.bind(this))}
				</div>
			</Container>
		);
	}
}

FeaturedLogos.defaultProps = {
	showLinks: false,
};

FeaturedLogos.propTypes = {
	showLinks: PropTypes.bool.isRequired,
	maxLogos: PropTypes.number,
	fillClass: PropTypes.string,
};

export default FeaturedLogos;
